import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BellModalModule } from '../modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { CanDeactivateComponent } from './can-deactivate.component.guard';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BellModalModule,
    TranslateModule
  ],
  providers: [
    CanDeactivateComponent
  ]
})
export class CanDeactivateModule { }
