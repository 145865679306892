import { WarehouseMultiChangeComponent } from './pages/warehouse-multi-change/warehouse-multi-change.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { UserResolver } from './resolvers/user.resolver';
import { CanDeactivateComponent } from '../lib/components/can-deactivate/can-deactivate.component.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {user: UserResolver},
    loadComponent: () =>  import('./pages/app-wrapper/app-wrapper.component').then(m => m.AppWrapperComponent),
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
          },
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/product-list/product-list.component').then(m => m.ProductListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/product-details/product-details.component').then(m => m.ProductDetailsComponent),
          },
          {
            path: ':product_id',
            loadComponent: () => import('./pages/product-details/product-details.component').then(m => m.ProductDetailsComponent),
          },
        ]
      },
      {
        path: 'semi-products',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/semi-product-list/semi-product-list.component').then(m => m.SemiProductListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/semi-product-details/semi-product-details.component').then(m => m.SemiProductDetailsComponent),
          },
          {
            path: ':semi-product_id',
            loadComponent: () => import('./pages/semi-product-details/semi-product-details.component').then(m => m.SemiProductDetailsComponent),
          },
        ]
      },
      {
        path: 'product-categories',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/product-category-list/product-category-list.component').then(m => m.ProductCategoryListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/product-category-details/product-category-details.component').then(m => m.ProductCategoryDetailsComponent),
          },
          {
            path: ':product-category_id',
            loadComponent: () => import('./pages/product-category-details/product-category-details.component').then(m => m.ProductCategoryDetailsComponent),
          },
        ]
      },
      {
        path: 'semi-product-categories',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/semi-product-category-list/semi-product-category-list.component').then(m => m.SemiProductCategoryListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/semi-product-category-details/semi-product-category-details.component').then(m => m.SemiProductCategoryDetailsComponent),
          },
          {
            path: ':semi-product-category_id',
            loadComponent: () => import('./pages/semi-product-category-details/semi-product-category-details.component').then(m => m.SemiProductCategoryDetailsComponent),
          },
        ]
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/client-list/client-list.component').then(m => m.ClientListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/client-details/client-details.component').then(m => m.ClientDetailsComponent),
          },
          {
            path: ':client_id',
            loadComponent: () => import('./pages/client-details/client-details.component').then(m => m.ClientDetailsComponent),
          },
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/order-list/order-list.component').then(m => m.OrderListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/order-details/order-details.component').then(m => m.OrderDetailsComponent),
          },
          {
            path: ':order_id',
            loadComponent: () => import('./pages/order-details/order-details.component').then(m => m.OrderDetailsComponent),
          },
        ]
      },
      {
        path: 'orders-products',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/orders-products-list/orders-products-list.component').then(m => m.OrdersProductsListComponent),
          },
        ]
      },
      {
        path: 'pricelist',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/pricelist-client-list/pricelist-client-list.component').then(m => m.PricelistComponent),
          },
          {
            path: 'client/:client_id',
            loadComponent: () => import('./pages/pricelist-client-details/pricelist-details.component').then(m => m.PricelistDetailsComponent),
          },
        ]
      },
      {
        path: 'employees',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/employee-list/employee-list.component').then(m => m.EmployeeListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/employee-details/employee-details.component').then(m => m.EmployeeDetailsComponent),
          },
          {
            path: ':employee_id',
            loadComponent: () => import('./pages/employee-details/employee-details.component').then(m => m.EmployeeDetailsComponent),
          },
        ]
      },
      {
        path: 'warehouse',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/warehouse-list/warehouse-list.component').then(m => m.WarehouseListComponent),
          },
          {
            path: 'input',
            canDeactivate: [CanDeactivateComponent],
            loadComponent: () => import('./pages/warehouse-multi-change/warehouse-multi-change.component').then(m => m.WarehouseMultiChangeComponent),
          },
          {
            path: 'output',
            canDeactivate: [CanDeactivateComponent],
            loadComponent: () => import('./pages/warehouse-multi-change/warehouse-multi-change.component').then(m => m.WarehouseMultiChangeComponent),
          },
          {
            path: ':product_id',
            loadComponent: () => import('./pages/warehouse-details/warehouse-details.component').then(m => m.WarehouseDetailsComponent),
          },
        ]
      },
      {
        path: 'purchase',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/purchase-list/purchase-list.component').then(m => m.PurchaseListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/purchase-details/purchase-details.component').then(m => m.PurchaseDetailsComponent),
          },
          {
            path: ':purchase_id',
            loadComponent: () => import('./pages/purchase-details/purchase-details.component').then(m => m.PurchaseDetailsComponent),
          },
        ]
      },
      {
        path: 'production-plans',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/production-plan-list/production-plan-list.component').then(m => m.ProductionPlanListComponent),
          },
          {
            path: 'new',
            loadComponent: () => import('./pages/production-plan-details/production-plan-details.component').then(m => m.ProductionPlanDetailsComponent),
          },
          {
            path: ':plan_id',
            loadComponent: () => import('./pages/production-plan-details/production-plan-details.component').then(m => m.ProductionPlanDetailsComponent),
          },
        ]
      },
      {
        path: 'bin',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/restore-list/restore-list.component').then(m => m.DeletedListComponent),
          },
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/profile/profile.component').then(m => m.ProfileComponent),
          },
        ]
      }
    ]
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'unauthorized',
    loadComponent: () => import('./pages/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent),
  },
];
