import { BellModalService } from '../modal/modal.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfirmOptions } from '../modal/models/confirm-options';

export interface CanDeactivateComponentInterface {
  canDeactivate: () => Observable<boolean> | boolean
}

@Injectable()
export class CanDeactivateComponent {

  constructor(
    private modalService: BellModalService,
    private translateService: TranslateService
  ) {}

  canDeactivate(component: CanDeactivateComponentInterface): Observable<boolean> | boolean {
    return component.canDeactivate() ? true : this.openConfirmationModal();
  }

  openConfirmationModal(): any {
    let result: boolean = false;
    const modalConfig: ConfirmOptions = {
      title: this.translateService.instant('Warning'),
      body: this.translateService.instant('Are you sure you want to leave this page? Any unsaved data will be lost permanently'),
      type: 'warning',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {result = false }
        },
        {
          text: 'Yes',
          handler: () => {result = true }
        }
      ]
    }
    const modalRef = this.modalService.confirmReturn(modalConfig);
    return modalRef.result.then(() => {
      return result;
    })

  }
}
